/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
import Vue from "vue";

// Replace underscores with spaces and capitalize words
Vue.filter("humanize", (value) => {
  if (value) {
    let i;
    const frags = value.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  }
});

Vue.filter("formatAmount", (value) => {
  if (typeof value !== "number") {
    return value;
  }
  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    // maximumFractionDigits: 0,
  });
  return formatter.format(value);
});

Vue.filter("formatAmountAndroid", (value) => {
  if (typeof value !== "number") {
    return value;
  }
  const formattedValue =
    "Rp " + value.toFixed(0).replace(/\d(?=(\d{3})+$)/g, "$&.");
  return formattedValue;
});

Vue.filter("formatAmountInvoice", (value) => {
  if (typeof value !== "number") {
    return value;
  }
  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  });
  return formatter.format(value).replace(/^(\D+)/, "Rp ");
});
// Vue.filter('formatInvoice', value => {
//   if (typeof value !== 'number') {
//     return value
//   }
//   const formatter = new Intl.NumberFormat('id-ID', {
//     style: 'currency',
//     currency: 'IDR',
//   })
//   return formatter.format(value)
// })
// Format amount and replace comma with dot
Vue.filter("formatInvoice", (value) => {
  if (value) {
    return Number(value).toLocaleString().replace(/,/g, ".");
  }
  return 0;
});
